//@mui material components
import React, { useState } from "react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import toast, { Toaster } from "react-hot-toast";

function RegistrationForm() {
  const collegeUUID = localStorage.getItem("userId");
  const [formData, setFormData] = useState({
    course: "",
    staff_name: "",
    phone: "",
    description: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Set submitting state to true

    const payload = {
      college: collegeUUID,
      ...formData,
    };

    try {
      const response = await fetch(
        "https://collegetrainingbackend.roririsoft.com/cms/api/enquiry/cud/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        toast.success("Form submitted successfully!");
        setFormData({
          course: "",
          staff_name: "",
          phone: "",
          description: "",
        });
      } else {
        const errorData = await response.json();
        toast.error("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 0 }}>
      <Card sx={{ width: 550, padding: 2 }}>
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium" gutterBottom>
            New Course Inquiry
          </MDTypography>
        </MDBox>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Course Name"
                variant="outlined"
                name="course"
                value={formData.course}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Faculty Name"
                variant="outlined"
                name="staff_name"
                value={formData.staff_name}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                type="tel"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                multiline
                rows={4}
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={isSubmitting}
              sx={{ color: "white !important" }}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </form>
      </Card>
    </Box>
  );
}

export default RegistrationForm;
