// src/services/authService.js
const BASE_URL = "https://collegetrainingbackend.roririsoft.com/cms/api";
//signin
export const login = async (username, password) => {
  try {
    const response = await fetch(`${BASE_URL}/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      throw new Error("Invalid username or password.");
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

//sign-up
export const fetchColleges = async () => {
  try {
    const response = await fetch(`${BASE_URL}/college/list/`);
    if (!response.ok) throw new Error("Failed to fetch colleges");
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchCourses = async () => {
  try {
    const response = await fetch(`${BASE_URL}/course/list/`);
    if (!response.ok) throw new Error("Failed to fetch courses");
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const registerStudent = async (payload) => {
  try {
    const response = await fetch(`${BASE_URL}/student/cud/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) throw new Error("Registration failed");
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};
