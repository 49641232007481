import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const ImagePreviewModal = ({ open, imageUrl, onClose }) => {
  const [zoomLevel, setZoomLevel] = useState(1); // State for zoom level

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 3)); // Maximum zoom level is 3
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 0.5)); // Minimum zoom level is 0.5
  };

  const handleResetZoom = () => {
    setZoomLevel(1); // Reset zoom level to default
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Image Preview
        <div style={{ position: "absolute", top: 8, right: 8 }}>
          <IconButton onClick={handleZoomIn} sx={{ color: "#1976d2" }} aria-label="zoom in">
            <ZoomInIcon />
          </IconButton>
          <IconButton onClick={handleZoomOut} sx={{ color: "#1976d2" }} aria-label="zoom out">
            <ZoomOutIcon />
          </IconButton>
          {/* <IconButton onClick={handleResetZoom} color="primary" aria-label="reset zoom">
            <CloseIcon />
          </IconButton> */}
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "center", overflow: "hidden" }}>
          <img
            src={imageUrl}
            alt="Preview"
            style={{
              width: `${zoomLevel * 100}%`,
              height: "auto",
              borderRadius: "10px",
              transition: "transform 0.3s",
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Add PropTypes validation for the props
ImagePreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImagePreviewModal;
