import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tooltip from "@mui/material/Tooltip";
import { fetchTaskDetails, uploadFile, addTask } from "api/trainerapi/api";
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const Taskdetails = () => {
  const navigate = useNavigate();
  const collegeUUID = localStorage.getItem("selectedCollegeUuid");
  const coursetaskUUID = localStorage.getItem("selectedCourseUuid");
  const trainerUUID = localStorage.getItem("trainerUuid");

  const [tableData, setTableData] = useState([]);
  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);
  const [newTask, setNewTask] = useState({
    courseName: "",
    title: "",
    date: "",
    pdf: null,
    feedback: "",
  });
  const [fileUploading, setFileUploading] = useState(false);
  const [isAddingTask, setIsAddingTask] = useState(false);

  useEffect(() => {
    const loadTaskDetails = async () => {
      try {
        const data = await fetchTaskDetails(collegeUUID, coursetaskUUID, trainerUUID);
        if (data.status === "success") {
          const tasks = data.data.map((task) => ({
            taskUuid: task.uuid,
            courseName: task.course_details.identity,
            title: task.identity,
            date: formatDate(task.created_at),
            pdf: task.class_file_details?.file || "",
          }));
          setTableData(tasks);
        }
      } catch (error) {
        console.error("Error fetching task details:", error);
      }
    };

    loadTaskDetails();
  }, [collegeUUID, coursetaskUUID, trainerUUID]);

  const handleOpenModal = () => {
    setAddTaskModalOpen(true);
  };

  const handleCloseModal = () => {
    setAddTaskModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileUploading(true);
      try {
        const result = await uploadFile(file);
        if (result.status === "success") {
          setNewTask((prevState) => ({ ...prevState, pdf: result.data.uuid }));
        } else {
          console.error("File upload failed:", result);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setFileUploading(false);
      }
    }
  };

  const handleAddTask = async () => {
    setIsAddingTask(true);
    const payload = {
      uuid: newTask.title,
      identity: newTask.title,
      college: collegeUUID,
      course: coursetaskUUID,
      class_file: newTask.pdf,
      trainer: trainerUUID,
    };

    try {
      const result = await addTask(payload);
      if (result.status === "success") {
        // Refetch task details
        const data = await fetchTaskDetails(collegeUUID, coursetaskUUID, trainerUUID);
        if (data.status === "success") {
          const tasks = data.data.map((task) => ({
            taskUuid: task.uuid,
            courseName: task.course_details.identity,
            title: task.identity,
            date: formatDate(task.created_at),
            pdf: task.class_file_details?.file || "",
          }));
          setTableData(tasks);
        }
        setNewTask({ courseName: "", title: "", date: "", pdf: null, feedback: "" });
        handleCloseModal();
        toast.success("Task added successfully!");
      } else {
        toast.error("Task creation failed!");
      }
    } catch (error) {
      toast.error("Error adding task!");
    } finally {
      setIsAddingTask(false);
    }
  };

  return (
    <>
      <Toaster />
      <MDBox
        width="100%"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        sx={{ px: 3, py: 3 }}
      >
        <DashboardNavbar />
        <br />
        <MDBox
          display="flex"
          alignItems="center"
          px={3}
          py={2}
          bgcolor="#f5f5f5"
          borderRadius="8px"
          boxShadow="1px 1px 5px rgba(0, 0, 0, 0.1)"
          mb={3}
        >
          <IconButton
            onClick={() => navigate("/trainerdashboard")}
            sx={{
              color: "#1976d2",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "50%",
              padding: "10px",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                transform: "scale(1.1)",
              },
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}
          bgcolor="#f5f5f5"
          borderRadius="8px"
          boxShadow="1px 1px 5px rgba(0, 0, 0, 0.1)"
          mb={3}
        >
          <MDTypography variant="h6">Task Dashboard</MDTypography>
          <Button
            sx={{ color: "white !important" }}
            variant="contained"
            color="primary"
            onClick={handleOpenModal}
          >
            Add Task
          </Button>
        </MDBox>

        <MDBox flex="1" display="flex" flexDirection="column" sx={{ m: 0, px: 0 }}>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12} sx={{ height: "100%" }}>
              <Card sx={{ height: "100%", borderRadius: 0 }}>
                <MDBox pt={3} sx={{ height: "100%" }}>
                  {tableData.length === 0 ? (
                    <MDTypography variant="h6" color="textSecondary" align="center">
                      No tasks available
                    </MDTypography>
                  ) : (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Course Name", accessor: "courseName", align: "left" },
                          { Header: "Title", accessor: "title", align: "left" },
                          { Header: "Date", accessor: "date", align: "center" },
                          {
                            Header: "Lesson",
                            accessor: "download",
                            align: "center",
                            Cell: ({ row }) => (
                              // <Tooltip title={row.original.pdf.split("/").pop()} arrow>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  window.open(row.original.pdf, "_blank");
                                }}
                                sx={{
                                  backgroundColor: "#1976d2",
                                  color: "#fff",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  padding: "8px 16px",
                                  borderRadius: "8px",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                  transition: "all 0.3s ease",
                                  "&:hover": {
                                    backgroundColor: "#1565c0",
                                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                                  },
                                }}
                              >
                                View Lesson
                              </Button>
                              // </Tooltip>
                            ),
                          },

                          { Header: "View Feedback", accessor: "viewFeedback", align: "center" },
                        ],
                        rows: tableData.map((task) => ({
                          ...task,
                          viewFeedback: (
                            <Button
                              variant="contained"
                              onClick={() =>
                                navigate("/feedback", { state: { taskUuid: task.taskUuid } })
                              }
                              sx={{
                                backgroundColor: "#388e3c",
                                color: "#fff",
                                fontWeight: "bold",
                                textTransform: "none",
                                padding: "8px 16px",
                                borderRadius: "8px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                transition: "all 0.3s ease",
                                "&:hover": {
                                  backgroundColor: "#2e7d32",
                                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                                },
                              }}
                            >
                              View Feedback
                            </Button>
                          ),
                        })),
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                      sx={{ height: "100%" }}
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />

        {/* Add Task Modal */}
        <Modal open={addTaskModalOpen} onClose={handleCloseModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              width: "400px",
            }}
          >
            <MDTypography variant="h6">Add Task</MDTypography>
            <TextField
              label="Title"
              name="title"
              value={newTask.title}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <input
              type="file"
              accept=".pdf,.ppt,.pptx"
              onChange={handleFileChange}
              style={{ marginBottom: "16px" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddTask}
              fullWidth
              sx={{ color: "white !important" }}
              disabled={fileUploading || isAddingTask} // Disable if file is uploading or task is being added
            >
              {fileUploading
                ? "Uploading File..." // Show message when file is uploading
                : isAddingTask
                ? "Adding Task..." // Show message when task is being added
                : "Add Task"}
            </Button>
          </div>
        </Modal>
      </MDBox>
    </>
  );
};

Taskdetails.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      courseName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      pdf: PropTypes.string.isRequired,
      feedback: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Taskdetails;
