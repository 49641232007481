import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { toast, Toaster } from "react-hot-toast";
import { fetchColleges, fetchCourses, registerStudent } from "api/auth/api";
function Cover() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    collegeName: "",
    courseName: "",
    year: "I",
    fees: "",
    department: "",
    rollNumber: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [colleges, setColleges] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const collegeData = await fetchColleges();
        const courseData = await fetchCourses();
        setColleges(collegeData);
        setCourses(courseData);
      } catch (error) {
        toast.error("Error loading data. Please try again later.");
      }
    };
    loadData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const selectedCollege = colleges.find((college) => college.identity === formData.collegeName);
    const selectedCourse = courses.find((course) => course.identity === formData.courseName);

    const payload = {
      identity: formData.name,
      email: formData.email,
      phone: formData.contactNumber,
      college: selectedCollege ? selectedCollege.uuid : null,
      course: selectedCourse ? selectedCourse.uuid : null,
      roll_number: formData.rollNumber,
      year: formData.year,
      fees: formData.fees === "" ? 0 : formData.fees,
      department: formData.department,
    };

    try {
      await registerStudent(payload);
      toast.success("Registration successful!");
      navigate("/sign-in");
    } catch (error) {
      toast.error("Registration failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Toaster />
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-10}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Join us today
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your details to join our course!
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label="Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="email"
                    label="Email"
                    name="email"
                    variant="standard"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="tel"
                    label="Contact Number"
                    name="contactNumber"
                    variant="standard"
                    fullWidth
                    value={formData.contactNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel>College Name</InputLabel>
                    <Select name="collegeName" value={formData.collegeName} onChange={handleChange}>
                      {colleges.map((college) => (
                        <MenuItem key={college.id} value={college.identity}>
                          {college.identity}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel>Course Name</InputLabel>
                    <Select name="courseName" value={formData.courseName} onChange={handleChange}>
                      {courses.map((course) => (
                        <MenuItem key={course.id} value={course.identity}>
                          {course.identity}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel>Year</InputLabel>
                    <Select name="year" value={formData.year} onChange={handleChange}>
                      <MenuItem value="I">I</MenuItem>
                      <MenuItem value="II">II</MenuItem>
                      <MenuItem value="III">III</MenuItem>
                      <MenuItem value="IV">IV</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label="Department"
                    name="department"
                    variant="standard"
                    fullWidth
                    value={formData.department}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="number"
                    label="Fees"
                    name="fees"
                    variant="standard"
                    fullWidth
                    value={formData.fees}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label="College Roll Number (Password)"
                    name="rollNumber"
                    variant="standard"
                    fullWidth
                    value={formData.rollNumber}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting} // Disable button
                >
                  {isSubmitting ? "Registering..." : "Register Now"} {/* Change button text */}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    </>
  );
}

export default Cover;
