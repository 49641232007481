import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tooltip from "@mui/material/Tooltip";
import toast, { Toaster } from "react-hot-toast";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const Syllabus = () => {
  const navigate = useNavigate();
  const studentUUID = localStorage.getItem("studentUuid");

  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchTaskDetails = async () => {
      const apiUrl = `https://collegetrainingbackend.roririsoft.com/cms/api/student/syllabus/${studentUUID}/`;
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data.status === "success") {
          const tasks = data.data.map((task) => ({
            ClassUuid: task.uuid,
            title: task.identity,
            date: formatDate(task.created_at),
            pdf: task.class_file_details?.file || "",
            feedback: task.feedback ? task.feedback.identity : "",
          }));
          setTableData(tasks);
        }
      } catch (error) {
        toast.error("Error fetching task details:", error);
      }
    };

    fetchTaskDetails();
  }, [studentUUID]);

  const handleOpenModal = (task) => {
    setCurrentTask(task);
    setFeedback(task.feedback || ""); // Use task.feedback directly
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFeedback("");
  };

  const handleAddFeedback = async () => {
    if (feedback.trim()) {
      setLoading(true);
      const feedbackPayload = {
        identity: feedback,
        student: studentUUID,
        daily_class: currentTask.ClassUuid,
      };

      try {
        const response = await fetch(
          "https://collegetrainingbackend.roririsoft.com/cms/api/student/feedback/cud/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(feedbackPayload),
          }
        );

        if (response.ok) {
          const updatedTask = {
            ...currentTask,
            feedback,
          };

          setTableData((prevData) =>
            prevData.map((task) => (task.ClassUuid === currentTask.ClassUuid ? updatedTask : task))
          );
          toast.success("Feedback added successfully!");
          handleCloseModal();
        } else {
          toast.error("Failed to add feedback. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Feedback cannot be empty");
    }
  };

  return (
    <>
      <Toaster />
      <MDBox
        width="100%"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        sx={{ px: 3, py: 3 }}
      >
        <DashboardNavbar />
        <br />
        <MDBox
          display="flex"
          alignItems="center"
          px={3}
          py={2}
          bgcolor="#f5f5f5"
          borderRadius="8px"
          boxShadow="1px 1px 5px rgba(0, 0, 0, 0.1)"
          mb={3}
        >
          <IconButton
            onClick={() => navigate("/student-dashboard")}
            sx={{
              color: "#1976d2",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "50%",
              padding: "10px",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                transform: "scale(1.1)",
              },
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 28 }} /> {/* Increase icon size */}
          </IconButton>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}
          bgcolor="#f5f5f5"
          borderRadius="8px"
          boxShadow="1px 1px 5px rgba(0, 0, 0, 0.1)"
          mb={3}
        >
          <MDTypography variant="h6">Syllabus</MDTypography>
        </MDBox>

        <MDBox flex="1" display="flex" flexDirection="column" sx={{ m: 0, px: 0 }}>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12} sx={{ height: "100%" }}>
              <Card sx={{ height: "100%", borderRadius: 0 }}>
                <MDBox pt={3} sx={{ height: "100%" }}>
                  {tableData.length === 0 ? (
                    <MDTypography variant="h6" color="textSecondary" align="center">
                      No tasks available
                    </MDTypography>
                  ) : (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Title", accessor: "title", align: "left" },
                          { Header: "Date", accessor: "date", align: "center" },
                          {
                            Header: "Lesson",
                            accessor: "download",
                            align: "center",
                            Cell: ({ row }) => (
                              // <Tooltip title={row.original.pdf.split("/").pop()} arrow>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  window.open(row.original.pdf, "_blank");
                                }}
                                sx={{
                                  backgroundColor: "#1976d2",
                                  color: "#fff",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  padding: "8px 16px",
                                  borderRadius: "8px",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                  transition: "all 0.3s ease",
                                  "&:hover": {
                                    backgroundColor: "#1565c0",
                                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                                  },
                                }}
                              >
                                View Lesson
                              </Button>
                              // </Tooltip>
                            ),
                          },
                          {
                            Header: "Feedback",
                            accessor: "studentFeedback",
                            align: "center",
                            Cell: ({ row }) => (
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#388e3c",
                                  color: "#fff",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  padding: "8px 16px",
                                  borderRadius: "8px",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                  transition: "all 0.3s ease",
                                  "&:hover": {
                                    backgroundColor: "#2e7d32",
                                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                                  },
                                }}
                                onClick={() => handleOpenModal(row.original)}
                              >
                                View/Add Feedback
                              </Button>
                            ),
                          },
                        ],
                        rows: tableData,
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                      sx={{ height: "100%" }}
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />

        {/* Modal to add/view feedback */}
        <Modal open={openModal} onClose={handleCloseModal}>
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 3,
            }}
          >
            <MDTypography variant="h6">Feedback for {currentTask?.title}</MDTypography>
            <TextField
              label="Feedback"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              onClick={handleAddFeedback}
              variant="contained"
              sx={{ mr: 2, color: "white !important" }}
              disabled={loading} // Disable the button when loading
            >
              {loading ? "Saving..." : "Save Feedback"}{" "}
              {/* Change button text based on loading state */}
            </Button>
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{ color: "black !important" }}
              disabled={loading}
            >
              Cancel
            </Button>
          </MDBox>
        </Modal>
      </MDBox>
    </>
  );
};

Syllabus.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      pdf: PropTypes.string.isRequired,
      feedback: PropTypes.string.isRequired,
      studentFeedback: PropTypes.arrayOf(
        PropTypes.shape({
          studentName: PropTypes.string.isRequired,
          feedback: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
  }).isRequired,
};

export default Syllabus;
