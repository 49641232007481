import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchFeedbackByTask } from "api/trainerapi/api";
const FeedbackPage = () => {
  const location = useLocation();
  const { taskUuid } = location.state || {};
  const [feedbackData, setFeedbackData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/task-details");
  };

  useEffect(() => {
    if (taskUuid) {
      const getFeedback = async () => {
        setLoading(true);
        const data = await fetchFeedbackByTask(taskUuid);
        setFeedbackData(data);
        setLoading(false);
      };

      getFeedback();
    }
  }, [taskUuid]);

  if (loading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </MDBox>
    );
  }

  return (
    <>
      <MDBox
        width="100%"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        sx={{ px: 3, py: 3 }}
      >
        <DashboardNavbar />
        <MDBox
          display="flex"
          alignItems="center"
          px={3}
          py={2}
          bgcolor="#f5f5f5"
          borderRadius="8px"
          mb={3}
        >
          <IconButton
            onClick={handleBackClick}
            sx={{
              color: "#1976d2",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "50%",
              padding: "10px",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                transform: "scale(1.1)",
              },
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </MDBox>
        <MDBox px={3} py={2} bgcolor="#f5f5f5" borderRadius="8px" mb={3}>
          <MDTypography variant="h6">Student Feedback</MDTypography>
        </MDBox>
        <MDBox flex="1" display="flex" flexDirection="column" alignItems="center">
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <MDBox pt={3}>
                  {feedbackData.length === 0 ? (
                    <MDTypography variant="h6" color="textSecondary" align="center">
                      No feedback available
                    </MDTypography>
                  ) : (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Student Name", accessor: "studentName", align: "left" },
                          { Header: "Feedback", accessor: "feedback", align: "left" },
                          { Header: "Date", accessor: "createdAt", align: "left" },
                        ],
                        rows: feedbackData.map((feedback) => ({
                          studentName: feedback.student_details?.identity || "N/A",
                          feedback: feedback.identity || "No feedback provided",
                          createdAt: feedback.created_at
                            ? new Date(feedback.created_at).toLocaleDateString()
                            : "N/A",
                        })),
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </MDBox>
    </>
  );
};

export default FeedbackPage;
