import React, { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import {
  fetchStudentDetails,
  fetchStudentTasks,
  addTask,
  updateTask,
  uploadImage,
} from "api/studentsdashapi/api";
import toast, { Toaster } from "react-hot-toast";
import ImagePreviewModal from "./ImagePreviewModal/index";

function Studentdashboard() {
  const navigate = useNavigate();
  const studentUUID = localStorage.getItem("userId");
  const [filteredRows, setFilteredRows] = useState([]);
  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);
  const [editTaskModalOpen, setEditTaskModalOpen] = useState(false);
  const [taskUUIDToEdit, setTaskUUIDToEdit] = useState(null);
  const [loading, setLoading] = useState(false); // Loader state for fetching
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // Track upload status
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImagePreviewOpen(true);
  };
  const [studentDetails, setStudentDetails] = useState({
    name: "",
    course: "N/A",
    course_uuid: "",
    college: "",
  });

  const [newTask, setNewTask] = useState({
    taskTitle: "",
    description: "",
    assignDate: "",
    status: "Pending",
  });
  const [editTaskIndex, setEditTaskIndex] = useState(null);
  const [editTaskDetails, setEditTaskDetails] = useState({
    taskTitle: "",
    description: "",
    assignDate: "",
    status: "Pending",
    uploadImage: null,
  });

  const [image, setImage] = useState({
    uploadImage: null,
    imagePreview: null,
  });

  const [imageUuid, setImageUuid] = useState(null);

  const handleImageUpload = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        "https://collegetrainingbackend.roririsoft.com/cms/task/image/",
        {
          method: "POST",
          body: formData,
          headers: {
            // Include headers only if necessary
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Image uploaded successfully:", data);
        console.log("Image uuid", data.data.uuid);
        const image_uuid = data.data.uuid;

        await setImageUuid(image_uuid);
      } else {
        console.error("Failed to upload image:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      setLoading(true);
      try {
        const data = await fetchStudentDetails(studentUUID);
        if (data.status === "success" && data.data) {
          setStudentDetails({
            name: data.data.identity,
            college: data.data.college,
            course: data.data.course || "N/A",
            course_uuid: data.data.course_uuid,
          });
        }
      } catch (error) {
        console.error("Error fetching student details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStudentData();
  }, [studentUUID]);

  const userListFetch = async () => {
    setLoading(true);
    try {
      const data = await fetchStudentTasks(studentUUID);
      if (data.status === "success" && Array.isArray(data.data)) {
        const tasks = data.data.map((task) => ({
          taskUUID: task.uuid,
          taskTitle: task.identity,
          description: task.description,
          assignDate: new Date(task.created_at).toLocaleDateString(),
          uploadImage: task.task_img_details ? (
            <img
              src={task.task_img_details.file}
              alt="Task"
              style={{ borderRadius: "5px", width: "50px", height: "50px" }}
              onClick={() => handleImageClick(task.task_img_details.file)}
            />
          ) : (
            <img
              src="https://via.placeholder.com/50"
              alt="Placeholder"
              style={{ borderRadius: "5px", width: "50px", height: "50px" }}
            />
          ),
          status: task.status,
        }));
        setFilteredRows(tasks);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const role = localStorage.getItem("userRole");

    // Ensure the user is a student
    if (role !== "student") {
      navigate("/sign-in");
    } else {
      userListFetch();
    }
  }, [navigate]);

  const handleAddTask = async () => {
    setIsUploading(true);
    const taskData = {
      identity: newTask.taskTitle,
      description: newTask.description,
      status: newTask.status,
      course: studentDetails.course_uuid,
      student: studentUUID,
    };

    try {
      const response = await addTask(taskData); // Simulating an API call
      if (response.status === "success") {
        toast.success("Task added successfully!");
        userListFetch(); // Refresh task list
        setAddTaskModalOpen(false);
        setNewTask({ taskTitle: "", description: "", assignDate: "", status: "Pending" });
      } else {
        toast.error("Error adding task");
      }
    } catch (error) {
      toast.error("Error adding task");
    } finally {
      setIsUploading(false); // Enable button and stop loading
    }
  };

  const handleEditTask = async () => {
    setIsUploading(true);
    const taskData = {
      identity: editTaskDetails.taskTitle,
      description: editTaskDetails.description,
      status: editTaskDetails.status,
      course: studentDetails.course_uuid,
      student: studentUUID,
    };

    try {
      // Send the PATCH request to the API
      const response = await fetch(
        `https://collegetrainingbackend.roririsoft.com/cms/api/task/cud/${editTaskDetails.taskUUID}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(taskData),
        }
      );

      const data = await response.json();

      if (response.ok && data.status === "success") {
        const task_uuid = data.data.data.uuid;
        const Uuids = {
          task_uuid: task_uuid,
          image_uuid: imageUuid,
        };

        if (imageUuid) {
          const res = await fetch(
            "https://collegetrainingbackend.roririsoft.com/cms/api/update/task/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(Uuids),
            }
          );
          const uploadData = await res.json();
          console.log("res upload", uploadData);
        }

        // Success Toast
        toast.success("Task updated successfully!");

        userListFetch();
        setEditTaskModalOpen(false);
      } else {
        // Error Toast
        toast.error(`Error updating task: ${data.message}`);
      }
    } catch (error) {
      // Error Toast
      toast.error("Error sending PATCH request: " + error.message);
      //console.error("Error sending PATCH request:", error);
    } finally {
      setIsUploading(false); // End uploading
    }
  };

  const handleEditButtonClick = (row) => {
    setEditTaskIndex(row.index);
    setEditTaskDetails(row);
    setTaskUUIDToEdit(row.taskUUID);
    setEditTaskModalOpen(true);
  };

  const enhancedRows = useMemo(() => {
    return filteredRows.map((row, index) => ({
      ...row,
      action: (
        <IconButton onClick={() => handleEditButtonClick(row)} style={{ color: "#1976d2" }}>
          <EditIcon />
        </IconButton>
      ),
    }));
  }, [filteredRows]);
  if (loading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </MDBox>
    );
  }
  return (
    <>
      {" "}
      <Toaster />
      <MDBox
        width="100%"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        sx={{ px: 3, py: 3 }}
      >
        <DashboardNavbar />
        <br />

        <MDBox
          display="flex"
          flexDirection={{ xs: "column", md: "row" }} // Column for small screens, row for larger screens
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}
          bgcolor="#f5f5f5"
          borderRadius="8px"
          boxShadow="1px 1px 5px rgba(0, 0, 0, 0.1)"
          mb={3}
          sx={{
            "@media (max-width: 850px)": {
              flexDirection: "column",
              alignItems: "flex-start", // Align content to start
            },
          }}
        >
          <MDTypography variant="h6">Student: {studentDetails.name}</MDTypography>
          <MDTypography variant="h6">College: {studentDetails.college}</MDTypography>
          <MDTypography variant="h6">Course: {studentDetails.course}</MDTypography>
          <Button
            sx={{ color: "white !important", mt: { xs: 2, md: 0 } }}
            variant="contained"
            color="primary"
            onClick={() => navigate("/syllabus")}
          >
            View Syllabus
          </Button>
          <Button
            sx={{ color: "white !important", mt: { xs: 2, md: 0 } }}
            variant="contained"
            color="primary"
            onClick={() => setAddTaskModalOpen(true)}
          >
            Add Task
          </Button>
        </MDBox>

        <MDBox flex="1" display="flex" flexDirection="column" sx={{ m: 0, px: 0 }}>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12} sx={{ height: "100%" }}>
              <Card sx={{ height: "100%", borderRadius: 0 }}>
                <MDBox pt={3} sx={{ height: "100%" }}>
                  {filteredRows.length === 0 ? (
                    <MDTypography variant="h6" color="textSecondary" align="center">
                      No tasks available
                    </MDTypography>
                  ) : (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Task Title", accessor: "taskTitle", align: "left" },
                          { Header: "Description", accessor: "description", align: "left" },
                          { Header: "Assign Date", accessor: "assignDate", align: "center" },
                          { Header: "Upload Image", accessor: "uploadImage", align: "center" },
                          { Header: "Status", accessor: "status", align: "center" },
                          { Header: "Action", accessor: "action", align: "center" },
                        ],
                        rows: enhancedRows,
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                      sx={{ height: "100%" }}
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />

        {/* Add Task Modal */}
        <Modal open={addTaskModalOpen} onClose={() => setAddTaskModalOpen(false)}>
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
            }}
          >
            <MDTypography variant="h6" mb={2}>
              Add Task
            </MDTypography>
            <TextField
              label="Task Title"
              fullWidth
              margin="normal"
              value={newTask.taskTitle}
              onChange={(e) => setNewTask({ ...newTask, taskTitle: e.target.value })}
            />
            <TextField
              label="Description"
              fullWidth
              margin="normal"
              value={newTask.description}
              onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
            />
            <Select
              fullWidth
              label="Status"
              value={newTask.status}
              onChange={(e) => setNewTask({ ...newTask, status: e.target.value })}
              margin="normal"
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>
            <Button
              sx={{ color: "white !important" }}
              variant="contained"
              color="primary"
              onClick={handleAddTask}
              disabled={isUploading} // Disable while adding
            >
              {isUploading ? "Adding task..." : "Add Task"} {/* Loader */}
            </Button>
          </MDBox>
        </Modal>

        {/* Edit Task Modal */}
        <Modal open={editTaskModalOpen} onClose={() => setEditTaskModalOpen(false)}>
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
            }}
          >
            <MDTypography variant="h6" mb={2}>
              Edit Task
            </MDTypography>
            <TextField
              label="Task Title"
              fullWidth
              margin="normal"
              value={editTaskDetails.taskTitle}
              onChange={(e) =>
                setEditTaskDetails({ ...editTaskDetails, taskTitle: e.target.value })
              }
            />
            <TextField
              label="Description"
              fullWidth
              margin="normal"
              value={editTaskDetails.description}
              onChange={(e) =>
                setEditTaskDetails({ ...editTaskDetails, description: e.target.value })
              }
            />
            <Select
              fullWidth
              sx={{ height: 45 }}
              label="Status"
              value={editTaskDetails.status}
              onChange={(e) => setEditTaskDetails({ ...editTaskDetails, status: e.target.value })}
              margin="normal"
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>

            <MDBox sx={{ mb: 2 }}>
              <MDTypography
                sx={{ fontSize: 12, marginTop: 1, left: 8, position: "relative" }}
                variant="body1"
              >
                Upload Image
              </MDTypography>
              <Button
                variant="outlined"
                component="label"
                sx={{
                  display: "block",
                  textAlign: "center",
                  width: "100%",
                  color: "white !important",
                }}
              >
                {image.uploadImage?.name || "Choose Image"}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setImage({
                        ...image,
                        uploadImage: file,
                        imagePreview: URL.createObjectURL(file),
                      });
                      handleImageUpload(file); // Upload the image after selecting
                    }
                  }}
                />
              </Button>

              {image.imagePreview && (
                <MDBox
                  sx={{
                    marginTop: 2,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px dashed grey",
                    borderRadius: 2,
                    padding: 2,
                  }}
                >
                  <img
                    src={image.imagePreview}
                    alt="Uploaded Preview"
                    style={{ maxWidth: "100%", maxHeight: "150px", borderRadius: "4px" }}
                  />
                </MDBox>
              )}
            </MDBox>

            <Button
              sx={{ color: "white !important" }}
              variant="contained"
              color="primary"
              onClick={handleEditTask}
              disabled={isUploading}
            >
              {isUploading ? "Saving..." : "Save Changes"}
            </Button>
          </MDBox>
        </Modal>
        <ImagePreviewModal
          open={imagePreviewOpen}
          imageUrl={selectedImage}
          onClose={() => setImagePreviewOpen(false)}
        />
      </MDBox>
    </>
  );
}

export default Studentdashboard;
