// api.js

const API_BASE_URL = "https://collegetrainingbackend.roririsoft.com";

export const fetchStudentDetails = async (studentUUID) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cms/api/student-details/${studentUUID}/`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching student details:", error);
    throw error;
  }
};

export const fetchStudentTasks = async (studentUUID) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cms/api/students/task/${studentUUID}/`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    throw error;
  }
};

export const addTask = async (taskData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cms/api/task/cud/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(taskData),
    });
    return await response.json();
  } catch (error) {
    console.error("Error adding task:", error);
    throw error;
  }
};

export const updateTask = async (taskUUID, taskData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cms/api/task/cud/${taskUUID}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(taskData),
    });
    return await response.json();
  } catch (error) {
    console.error("Error updating task:", error);
    throw error;
  }
};

export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await fetch(`${API_BASE_URL}/cms/task/image/`, {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Image upload failed");
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};
//feedbacl and  syllabus
export const fetchTaskDetails = async (studentUUID) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cms/api/student/syllabus/${studentUUID}/`);
    if (!response.ok) {
      throw new Error("Failed to fetch task details");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const addFeedback = async (feedbackPayload) => {
  try {
    const response = await fetch(`${API_BASE_URL}/cms/api/student/feedback/cud/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedbackPayload),
    });

    if (!response.ok) {
      throw new Error("Failed to add feedback");
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};
