import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { fetchTaskData, fetchFeedbackData } from "api/dashboardapi/api";
import ImagePreviewModal from "layouts/StudentDashboard/ImagePreviewModal/index";
const Studentmonitor = () => {
  const location = useLocation();
  const { uuid } = useParams();
  const [loading, setLoading] = useState(true);
  const [tasksData, setTasksData] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [imageSrc, setImageSrc] = useState(null); // State for image source
  const [imageModalOpen, setImageModalOpen] = useState(false); // State for modal
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/dashboard");
  };
  const handleImagePreview = (imageUrl) => {
    setImageSrc(imageUrl);
    setImageModalOpen(true);
  };
  const handleCloseModal = () => {
    setImageModalOpen(false);
    setImageSrc(null);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Fetch task data
      const taskData = await fetchTaskData(uuid);
      setTasksData(taskData);

      // Fetch feedback data
      const feedbackData = await fetchFeedbackData(uuid);
      setFeedbackData(feedbackData);

      setLoading(false);
    };

    fetchData();
  }, [uuid]);

  if (loading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </MDBox>
    );
  }

  return (
    <>
      <MDBox
        width="100%"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        sx={{ px: 3, py: 3 }}
      >
        <DashboardNavbar />
        <MDBox
          display="flex"
          alignItems="center"
          px={3}
          py={2}
          bgcolor="#f5f5f5"
          borderRadius="8px"
          mb={3}
        >
          <IconButton
            onClick={handleBackClick}
            sx={{
              color: "#1976d2",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "50%",
              padding: "10px",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                transform: "scale(1.1)",
              },
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 28 }} /> {/* Increase icon size */}
          </IconButton>
        </MDBox>

        {/* Tasks Table */}
        <MDBox px={3} py={2} bgcolor="#f5f5f5" borderRadius="8px" mb={3}>
          <MDTypography variant="h6">Student Tasks</MDTypography>
        </MDBox>
        <MDBox flex="1" display="flex" flexDirection="column" alignItems="center" mb={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <MDBox pt={3}>
                  {tasksData.length === 0 ? (
                    <MDTypography variant="h6" color="textSecondary" align="center">
                      No tasks available
                    </MDTypography>
                  ) : (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Task Title", accessor: "identity", align: "left" },
                          { Header: "Description", accessor: "description", align: "left" },
                          {
                            Header: "Assign Date",
                            accessor: "created_at",
                            align: "center",
                            Cell: ({ value }) => {
                              const date = new Date(value);
                              return date.toLocaleDateString(); // Formats the date to MM/DD/YYYY or based on locale
                            },
                          },
                          { Header: "Status", accessor: "status", align: "center" },
                          {
                            Header: "Task Image",
                            accessor: "task_img_details",
                            Cell: ({ row }) => (
                              <img
                                src={row.original.task_img_details?.file}
                                alt="Task Image"
                                style={{ width: 50, height: 50, cursor: "pointer" }}
                                onClick={() =>
                                  handleImagePreview(row.original.task_img_details?.file)
                                }
                              />
                            ),
                          },
                        ],

                        rows: tasksData,
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>

        {/* Feedback Table */}
        <MDBox px={3} py={2} bgcolor="#f5f5f5" borderRadius="8px" mb={3}>
          <MDTypography variant="h6">Student Feedback</MDTypography>
        </MDBox>
        <MDBox flex="1" display="flex" flexDirection="column" alignItems="center">
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <MDBox pt={3}>
                  {feedbackData.length === 0 ? (
                    <MDTypography variant="h6" color="textSecondary" align="center">
                      No feedback available
                    </MDTypography>
                  ) : (
                    <DataTable
                      table={{
                        columns: [
                          { Header: "Title", accessor: "title", align: "left" },
                          { Header: "Feedback", accessor: "identity", align: "left" },
                          { Header: "Date", accessor: "createdAt", align: "left" },
                        ],
                        rows: feedbackData,
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <br></br>
        <Footer />
        {/* Modal for Image Preview */}
        <ImagePreviewModal open={imageModalOpen} imageUrl={imageSrc} onClose={handleCloseModal} />
      </MDBox>
    </>
  );
};

// Prop validation for row and its nested properties
Studentmonitor.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      identity: PropTypes.string.isRequired,
      description: PropTypes.string,
      created_at: PropTypes.string.isRequired,
      status: PropTypes.string,
      task_img_details: PropTypes.shape({
        file: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Studentmonitor;
