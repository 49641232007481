import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import { navbar } from "examples/Navbars/DashboardNavbar/styles";
import { useMaterialUIController, setTransparentNavbar } from "context";
import { FaInstagram, FaLinkedin, FaFacebook, FaBars, FaSignOutAlt } from "react-icons/fa";
import { useMediaQuery } from "@mui/material"; // To handle mobile responsiveness
import logo from "../../../assets/images/RORIRI-LOGO.png";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { fixedNavbar, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);
  const isMobile = useMediaQuery("(max-width: 950px)"); // Mobile breakpoint
  const [menuOpen, setMenuOpen] = useState(false); // State to manage mobile menu visibility
  const navigate = useNavigate();
  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle mobile menu visibility
  };

  const handleLogout = () => {
    // Clear authentication data from localStorage or state
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("selectedCollegeUuid");
    localStorage.removeItem("selectedCourseUuid");
    localStorage.removeItem("studentUuid");
    localStorage.removeItem("trainerUuid");
    localStorage.removeItem("userId");
    // Navigate to the login page
    navigate("/sign-in");
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      sx={{
        backgroundColor: "#cbeaf5",
        borderRadius: "12px",
        color: "white",
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: isMobile ? "space-between" : "space-around", // Adjust for mobile view
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row", // Stack elements on mobile
          padding: "0 20px",
        }}
      >
        {/* Menu Icon (visible only on mobile) */}
        {isMobile && (
          <IconButton onClick={toggleMenu} sx={{ color: "white", marginLeft: "auto" }}>
            <FaBars size={24} />
          </IconButton>
        )}

        {/* Left Section: Company Name */}
        <MDBox textAlign="left" sx={{ fontSize: isMobile ? "1rem" : "0.9rem" }}>
          <a
            href="https://www.roririsoft.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            RORIRI SOFTWARE SOLUTIONS PVT.LTD.
          </a>
        </MDBox>

        {/* Next to Company Name: Location */}
        <MDBox
          textAlign="left"
          sx={{
            fontSize: isMobile ? "0.8rem" : "0.9rem",
            lineHeight: "1.5",
            display: menuOpen || !isMobile ? "block" : "none", // Show when menu is open or on desktop
          }}
        >
          <a
            href="https://www.google.com/maps?q=RORIRI+IT+PARK,+Kalakkad,+Tamil+Nadu+627502"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            RORIRI IT PARK, <br /> Kalakkad, <br /> Tamil Nadu 627502
          </a>
        </MDBox>

        {/* Center Section: Logo */}
        <MDBox textAlign="center" sx={{ width: isMobile ? "80%" : "auto" }}>
          <img src={logo} alt="Company Logo" style={{ width: "130px", height: "auto" }} />
        </MDBox>

        {/* Right Section: Contact Info */}
        <MDBox
          textAlign="left"
          sx={{
            fontSize: isMobile ? "0.8rem" : "0.9rem",
            lineHeight: "1.5",
            display: menuOpen || !isMobile ? "block" : "none", // Show when menu is open or on desktop
          }}
        >
          <MDBox>Phone: (+91) 7338941579</MDBox>
          <MDBox>Email: contact@roririsoft.com</MDBox>
        </MDBox>

        {/* Far Right: Social Media Links */}
        <MDBox
          display="flex"
          alignItems="center"
          sx={{
            marginTop: isMobile ? "10px" : "0", // Adjust for mobile spacing
            display: menuOpen || !isMobile ? "block" : "none", // Show when menu is open or on desktop
          }}
        >
          <IconButton
            component="a"
            href="https://www.instagram.com/roriri_soft?igsh=MTJmMHMxNm5pOTIy"
            target="_blank"
            aria-label="Instagram"
            sx={{ marginRight: "10px" }}
          >
            <FaInstagram style={{ color: "#E4405F", fontSize: isMobile ? "20px" : "24px" }} />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.linkedin.com/company/roriri-software-solutions-pvt-ltd/"
            target="_blank"
            aria-label="LinkedIn"
            sx={{ marginRight: "10px" }}
          >
            <FaLinkedin style={{ color: "#0077B5", fontSize: isMobile ? "20px" : "24px" }} />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.facebook.com/RoririSoftwareSolutionsPvtLtd/"
            target="_blank"
            aria-label="Facebook"
            sx={{ marginRight: "10px" }}
          >
            <FaFacebook style={{ color: "#4267B2", fontSize: isMobile ? "20px" : "24px" }} />
          </IconButton>
        </MDBox>
        {/* logout  */}
        <MDBox
          display="flex"
          alignItems="center"
          sx={{
            marginTop: isMobile ? "10px" : "0", // Adjust for mobile spacing
            display: menuOpen || !isMobile ? "block" : "none", // Show when menu is open or on desktop
          }}
        >
          <IconButton onClick={handleLogout} aria-label="Logout" sx={{ color: "white" }}>
            <FaSignOutAlt style={{ fontSize: isMobile ? "20px" : "24px" }} />
          </IconButton>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
