const BASE_URL = "https://collegetrainingbackend.roririsoft.com/cms/api";

export const fetchCourses = async (staffUUID) => {
  try {
    const response = await fetch(`${BASE_URL}/course/by-college/${staffUUID}/`);
    const result = await response.json();
    if (result.status === "success") {
      return result.data;
    }
    throw new Error("Failed to fetch course data");
  } catch (error) {
    throw error;
  }
};

export const fetchStudents = async (staffUUID) => {
  try {
    const response = await fetch(`${BASE_URL}/students/by-college/${staffUUID}/`);
    const result = await response.json();
    if (result.status === "success") {
      return result.data;
    }
    throw new Error("Failed to fetch student data");
  } catch (error) {
    throw error;
  }
};

export const updateStudent = async (student) => {
  try {
    const response = await fetch(`${BASE_URL}/student/cud/${student.uuid}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    });
    const result = await response.json();
    if (result.status === "success") {
      return result;
    }
    throw new Error("Failed to update student");
  } catch (error) {
    throw error;
  }
};

export const fetchTaskData = async (uuid) => {
  try {
    const response = await fetch(`${BASE_URL}/students/task/${uuid}/`);
    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error("Error fetching task data:", error);
    return [];
  }
};

export const fetchFeedbackData = async (uuid) => {
  try {
    const response = await fetch(`${BASE_URL}/student/feedback/list/${uuid}/`);
    const data = await response.json();
    return (
      data.data.map((item) => ({
        ...item,
        title: item.daily_class_details?.identity || "N/A", // Use fallback for missing identity
        createdAt: new Date(item.created_at).toLocaleDateString(),
      })) || []
    );
  } catch (error) {
    console.error("Error fetching feedback data:", error);
    return [];
  }
};
