import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate } from "react-router-dom";
import SchoolIcon from "@mui/icons-material/School"; // Material UI Icon for College
import CircularProgress from "@mui/material/CircularProgress"; // Loader
import { fetchColleges, fetchCoursesByCollege } from "api/trainerapi/api";
function CollegeDashboard() {
  const [colleges, setColleges] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false); // Track loading state
  const itemsPerPage = 4;
  const navigate = useNavigate();

  useEffect(() => {
    const userRole = localStorage.getItem("userRole");

    if (userRole !== "trainer") {
      navigate("/sign-in");
    }
  }, [navigate]);

  useEffect(() => {
    const loadColleges = async () => {
      setLoading(true);
      try {
        const data = await fetchColleges();
        if (data.status === "success") {
          setColleges(data.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadColleges();
  }, []);

  useEffect(() => {
    if (selectedCollege) {
      const loadCourses = async () => {
        setLoading(true);
        try {
          const data = await fetchCoursesByCollege(selectedCollege.uuid);
          if (data.status === "success") {
            setCourses(data.data);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      loadCourses();
    }
  }, [selectedCollege]);

  const currentItems = useMemo(() => {
    return colleges.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  }, [colleges, currentPage]);

  const nextPage = () => {
    if ((currentPage + 1) * itemsPerPage < colleges.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCardClick = (college) => {
    setSelectedCollege(college);
    localStorage.setItem("selectedCollegeUuid", college.uuid);
  };

  const handleCourseView = (course) => {
    localStorage.setItem("selectedCourseUuid", course.uuid);
    navigate(`/task-details`);
  };

  return (
    <MDBox
      width="100%"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      sx={{ px: 3, py: 3 }}
    >
      <DashboardNavbar />
      <br />
      <MDBox flex="1">
        {loading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress /> {/* Loader component */}
          </MDBox>
        ) : colleges.length === 0 ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
            <MDTypography variant="h6" color="textSecondary">
              No colleges are available.
            </MDTypography>
          </MDBox>
        ) : (
          <>
            <Grid container spacing={3}>
              {currentItems.map((college) => (
                <Grid key={college.id} item xs={12} md={6} lg={3}>
                  <MDBox mb={1.5} position="relative">
                    <Card
                      onClick={() => handleCardClick(college)}
                      sx={{
                        textAlign: "center",
                        p: 3,
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                        borderRadius: "12px",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
                        },
                        backgroundColor: "#f5f5f5", // Soft background color
                      }}
                    >
                      {/* College Icon in the center */}
                      <MDBox display="flex" justifyContent="center" alignItems="center" mb={2}>
                        <SchoolIcon sx={{ fontSize: 60, color: "#1976d2" }} />
                      </MDBox>
                      {/* College Name */}
                      <MDTypography
                        variant="h6"
                        color="textPrimary"
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "#333",
                          letterSpacing: "0.5px",
                        }}
                      >
                        {college.identity}
                      </MDTypography>
                    </Card>
                  </MDBox>
                </Grid>
              ))}
            </Grid>

            {/* Navigation buttons */}
            <MDBox display="flex" justifyContent="space-between" mt={2}>
              <MDTypography onClick={prevPage} sx={{ cursor: "pointer", color: "#1976d2" }}>
                Previous
              </MDTypography>
              <MDTypography onClick={nextPage} sx={{ cursor: "pointer", color: "#1976d2" }}>
                Next
              </MDTypography>
            </MDBox>

            {/* Display selected college courses */}
            {selectedCollege ? (
              <MDBox mt={4} p={4} borderRadius="md" boxShadow={3} bgcolor="grey.100">
                <MDTypography variant="h5" mb={3} color="primary.main">
                  Courses offered by {selectedCollege.identity}:
                </MDTypography>
                <Grid container spacing={2}>
                  {courses.length > 0 ? (
                    courses.map((course, index) => (
                      <Grid key={index} item xs={12} md={6} lg={3}>
                        <Card
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "150px",
                            borderRadius: "8px",
                            textAlign: "center",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#fff",
                            transition: "box-shadow 0.3s ease",
                            "&:hover": {
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            },
                          }}
                        >
                          <MDTypography variant="body1" color="textSecondary">
                            {course.identity}
                          </MDTypography>
                          {/* Button to view course */}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleCourseView(course)}
                            sx={{ mt: 2, color: "white !important" }}
                          >
                            View Course
                          </Button>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <MDTypography variant="body1" color="textSecondary">
                      No courses available for this college.
                    </MDTypography>
                  )}
                </Grid>
              </MDBox>
            ) : (
              <MDBox mt={4} p={4} borderRadius="md" bgcolor="grey.100">
                <MDTypography variant="h6" color="textSecondary">
                  Please select a college to view the courses.
                </MDTypography>
              </MDBox>
            )}
          </>
        )}
      </MDBox>
      <Footer />
    </MDBox>
  );
}

export default CollegeDashboard;
