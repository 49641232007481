const BASE_URL = "https://collegetrainingbackend.roririsoft.com/cms/api";
//trainer dashboard
export const fetchColleges = async () => {
  try {
    const response = await fetch(`${BASE_URL}/college/list/`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching colleges:", error);
    throw error;
  }
};

export const fetchCoursesByCollege = async (collegeUuid) => {
  try {
    const response = await fetch(`${BASE_URL}/course/by-college/${collegeUuid}/`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching courses:", error);
    throw error;
  }
};
//taskadd-trainer
export const fetchTaskDetails = async (collegeUUID, courseUUID, trainerUUID) => {
  const apiUrl = `${BASE_URL}/report/by-trainer/${collegeUUID}/${courseUUID}/${trainerUUID}/`;
  const response = await fetch(apiUrl);
  const data = await response.json();
  return data;
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(`${BASE_URL}/update/report/files/`, {
    method: "POST",
    body: formData,
  });
  const data = await response.json();
  return data;
};

export const addTask = async (taskData) => {
  const response = await fetch(`${BASE_URL}/trainer/report/cud/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(taskData),
  });
  const data = await response.json();
  return data;
};

//feedback-list
export const fetchFeedbackByTask = async (taskUuid) => {
  try {
    const response = await fetch(`${BASE_URL}/trainer/feedback/list/${taskUuid}/`);
    const result = await response.json();

    if (result.status === "success") {
      return result.data || []; // Return an empty array if data is null
    } else {
      console.error("Failed to fetch feedback:", result);
      return [];
    }
  } catch (error) {
    console.error("Error fetching feedback:", error);
    return [];
  }
};
